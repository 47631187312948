import color from 'color'

export const DEFAULT_COLOR = '#3e93af' // Hiago purple
export const LOADING_COLOR = '#CCC'

export const getColorVariants = (primaryColor = DEFAULT_COLOR) => {
  const primary = color(primaryColor)
  return {
    primary: primary.hex(),
    primaryDarker: primary.darken(0.3).hex(),
    primaryLighter: primary.lighten(0.3).hex(),
    primaryLightest: primary.lighten(1.25).hex(),
    primaryTransparent: primary.fade(0.68).string(),
  }
}
