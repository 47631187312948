import { useContext } from 'react'
import { I18nContext } from 'next-i18next'
import { NavDropdown } from 'react-bootstrap'
import { Router } from '~/i18n'

import FlagImage from '~/components/shared/FlagImage'

const LanguageSwitcher = () => {
  const { i18n: { language } } = useContext(I18nContext)

  const languages = {
    en: {
      country: 'us',
      label: 'English'
    },
    es: {
      country: 'es',
      label: 'Español'
    }
  }

  const selectedLanguage = languages[language]

  const changeLanguage = (lng) => {
    if (language !== lng) {
      const nextPath = Router.asPath.replace(language, lng)
      window.location.href = nextPath
    }
  }

  return (
    <NavDropdown title={(
      <>
        <FlagImage
          countryCode={selectedLanguage.country}
          title={selectedLanguage.label}
          className="mr-2"
          size={28}
        />
        <span className="d-lg-none">
          {selectedLanguage.label}
        </span>
        <span className="d-none d-lg-inline text-uppercase">
          {language}
        </span>
      </>
    )}
    >
      {Object.keys(languages).map(key => (
        <NavDropdown.Item key={key} onClick={() => changeLanguage(key)}>
          <FlagImage
            countryCode={languages[key].country}
            title={languages[key].label}
            className="mr-2"
            size={28}
          />
          {languages[key].label}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}

export default LanguageSwitcher
