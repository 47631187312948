import Head from 'next/head'
import { useRouter } from 'next/router'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'

import Header from '../Header'
import PublicHeader from '../PublicHeader'
import Footer from './Footer'
import { roles } from '~/utils/auth'
import OrganizationCSS from './OrganizationCSS'

import styles from './index.module.scss'
import ErrorBoundary from '../ErrorBoundary'
import { withTranslation } from '~/i18n'
import { useCurrentOrganization } from '~/utils/organization'

const Layout = ({ pageHeader, children, className, title, area, t, tReady, ...props }) => {
  const router = useRouter()
  const pathname = router.asPath
  const links = {
    'organization': [
      [t('navigation.emails'), ({ query }) => `/organizations/${query.slug}`],
      [t('navigation.meetings'), ({ query }) => `/organizations/${query.slug}/meetings`],
      [t('navigation.admin'), '/admin/emails/pending', roles.administrator],
    ],
    'main': [
      [t('navigation.organizations'), '/admin/organizations', roles.superadmin],
      [t('navigation.administrators'), '/admin/administrators', roles.superadmin],
      [t('navigation.contacts'), '/admin/contacts', roles.administrator],
      [t('navigation.allEmails'), '/admin/emails', roles.administrator],
      [t('navigation.pending'), '/admin/emails/pending', roles.administrator],
      [t('navigation.meetings'), '/admin/meetings', roles.administrator],
      [t('navigation.topics'), '/admin/topics', roles.administrator],
      [t('navigation.users'), '/admin/users', roles.administrator],
      [t('navigation.messages'), '/admin/messages', roles.administrator],
      [t('navigation.publicView'), ({ query }) => {
        const [organization] = useCurrentOrganization()
        return `/organizations/${organization.slug || ''}`
      }, roles.administrator],
    ],
  }
  const areaLinks = links[area]

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta httpEquiv="X-UA-Compatible" content="IE=EmulateIE9"></meta>
        <title>{title}</title>
        <link rel="canonical" href={`https://hiago.app${pathname}`} />
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}`}></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}');
            `,
          }}
        />
      </Head>
      <OrganizationCSS />
      <Header links={areaLinks} />
      <div
        id="ie-fallback"
        dangerouslySetInnerHTML={{
          __html: `
          <!--[if IE]>
            <div style="padding: 120px; text-align: center;">
              <h1>Your browser is not supported</h1>
              <p>This website is not compatible with Internet Explorer or Edge Legacy.</p>
              <p>We suggest you use <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>.</p>
              <style>
                nav, .container, footer { display: none; }
                h1, a {
                  color: blue;
                }
              </style>
            </div>
          <![endif]-->
      `}} />

      <Container {...props} className={className || styles.container}>
        {pageHeader && <PublicHeader>{pageHeader}</PublicHeader>}
        <div className={styles.content}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </Container>
      <Footer />
      <script
          dangerouslySetInnerHTML={{
            __html: `
            if (navigator.userAgent.search(/Edge/i) !== -1) {
              document.getElementById("ie-fallback").innerHTML = '<div style="padding: 120px; text-align: center;"><h1>Your browser is not supported</h1> <p>This website is not compatible with Internet Explorer or Edge Legacy.</p><p>We suggest you use <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>.</p><style>nav, .container, footer { display: none; } h1, a {color: blue;}</style></div>'
            }
            `,
          }}
        />
    </>
  )
}

Layout.propTypes = {
  area: PropTypes.oneOf(['organization', 'main']).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  pageHeader: PropTypes.node,
  className: PropTypes.string,
}

Layout.defaultProps = {
  title: 'Hiago',
}

export default withTranslation('common')(Layout)
