import PropTypes from 'prop-types'
import styles from './index.module.scss'

const PublicHeader = ({
  children,
  ...props
}) => (
  <div {...props} className={styles.publicHeader}>
    {children}
  </div>
)

PublicHeader.propTypes = {
  children: PropTypes.node,
}

PublicHeader.defaultProps = {
  children: null,
}

export default PublicHeader
