import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import styles from './Footer.module.scss'
import withLocale from '~/hocs/locale'

const Footer = ({ support, privacyPolicy, poweredBy }) => {
  const links = [
    [support, 'http://hiago.com/contact-us'],
    [privacyPolicy, 'http://hiago.com/privacy-policy']
  ]

  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <Col md="auto">
            <img src="/logo/logo.svg" height="32" alt="Hiago small logo" />
          </Col>
          <Col>
            {links.map(([title, url], i) => (
              <React.Fragment key={i}>
                {i > 0 && ' | '}
                <a href={url} key={url} target="_blank">{title}</a>
              </React.Fragment>
            ))}
          </Col>
          <Col md="auto">
            {poweredBy} <a href="//Govgpt.biz" target="_blank">Govgpt.biz</a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  support: PropTypes.string.isRequired,
  privacyPolicy: PropTypes.string.isRequired,
  poweredBy: PropTypes.string.isRequired,
}

export default withLocale('common', 'footer')(Footer)
