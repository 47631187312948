import {
  Alert
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


const ErrorBoundary = ({ className, onClose, ...props }) => (
  <Sentry.ErrorBoundary
    fallback={(
      <Alert variant="danger" className={className} onClose={onClose} dismissible={!!onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
        An unexpected error has occurred.
      </Alert>
    )}
    {...props}
  />
)

ErrorBoundary.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
}

ErrorBoundary.defaultProps = {
  onClose: null,
  className: ''
}

export default ErrorBoundary
