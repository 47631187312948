import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { useCurrentOrganization } from '~/utils/organization'
import { currentUserHasRoles, isLoggedIn } from '~/utils/auth'
import Logo from './Logo'
import styles from './index.module.scss'

import LanguageSwitcher from '~/components/shared/LanguageSwitcher'
import { Link } from '~/i18n'
import withLocale from '~/hocs/locale'

const LoginStatus = dynamic(() => import('./LoginStatus'), { ssr: false })

const Header = ({ links, showLogin, signupLabel }) => {
  const { asPath, query } = useRouter()
  const [organization, loading] = useCurrentOrganization()
  const allowedLinks = links
    .filter(([_name, _path, role]) => (role ? currentUserHasRoles(role) : true))
    .map(([name, pathOrFunc]) => {
      const path = typeof pathOrFunc === 'function'
        ? pathOrFunc({ query })
        : pathOrFunc

      return [name, path]
    })
  let showLanguageSwitcher = true
  let showSignUp = false
  if (organization && organization.translationSupportEnabled === false) {
    showLanguageSwitcher = false
  }
  if (organization && organization.slug && !isLoggedIn()) {
    showSignUp = true
  }

  return (
    <Navbar fixed="top" expand="lg" bg="light" className={styles.navbar}>
      <Container>
        <Navbar.Brand className="py-0">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={asPath}>
            {allowedLinks.map(([title, path]) => (
              <Link href={path} passHref prefetch={false} key={path}>
                <Nav.Link>{title}</Nav.Link>
              </Link>
            ))}
          </Nav>
          {showLogin && (
          <Nav>
            <LoginStatus />
          </Nav>
          )}
          {showSignUp && (
            <Nav>
              <Link
                href="/organizations/[slug]/users/signup"
                as={`/organizations/${organization.slug}/users/signup`}
                passHref
                prefetch={false}
              >
                <Nav.Link>
                  {signupLabel}
                </Nav.Link>
              </Link>
            </Nav>
          )}
          <Nav>
            {showLanguageSwitcher && !loading && <LanguageSwitcher />}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

// eslint-disable-next-line consistent-return
export const linkTypeChecker = (props, propName, componentName) => {
  if (
    !Array.isArray(props[propName])
    || props[propName].length < 2
    || typeof props[propName][0] !== 'string'
    || !['string', 'function'].includes(typeof props[propName][1])
  ) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. A link represented by an array of title (string), path (string or function) and optionally role (string) should be supplied.`)
  }
}

Header.propTypes = {
  signupLabel: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(linkTypeChecker),
  showLogin: PropTypes.bool,
}

Header.defaultProps = {
  links: [],
  showLogin: true,
}

export default withLocale('common', 'header')(Header)
