import { get, kebabCase } from 'lodash'

import { useCurrentOrganization } from '~/utils/organization'
import { getColorVariants, DEFAULT_COLOR, LOADING_COLOR } from '~/utils/colors'

export default () => {
  const [organization, loading] = useCurrentOrganization()
  const primaryColor = loading ? LOADING_COLOR : get(organization, 'colorScheme.primaryColor', DEFAULT_COLOR)
  const variants = getColorVariants(primaryColor)

  const colors = Object.entries(variants).map(
    ([key, value]) => `--${kebabCase(key)}: ${value} !important`,
  ).join('; ')

  return (
    <style jsx global>
      {`
        :root {
          ${colors}
        }
      `}
    </style>
  )
}
