import PropTypes from 'prop-types'

const FlagImage = ({
  countryCode, size, title, ...props
}) => (
  <img
    src={`https://hatscripts.github.io/circle-flags/flags/${countryCode}.svg`}
    width={size}
    height={size}
    alt={title}
    {...props}
  />
)

FlagImage.propTypes = {
  countryCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

export default FlagImage
