import { useRouter } from 'next/router'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import { isLoggedIn, getCurrentUser } from '~/utils/auth'
import { GetCurrentOrganizationBySlug } from '~/graphql/Organization.gql'

export const useCurrentSlug = () => {
  const {
    query: { slug },
  } = useRouter()
  return slug
}

export const useCurrentOrganization = () => {
  const { asPath, query: { slug } } = useRouter()
  const { data, loading } = useQuery(GetCurrentOrganizationBySlug, { variables: { slug }, skip: !slug })
  if (asPath.includes('[slug]')) { // ugly, I know, see https://github.com/zeit/next.js/issues/8259
    return [null, true]
  }
  if (slug) {
    const organization = get(data, 'organizations.nodes[0]')
    return [organization, loading]
  }
  if (isLoggedIn()) {
    const user = getCurrentUser()
    return [get(user, 'organization'), false]
  }
  return [null, false]
}
