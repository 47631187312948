import { Link } from '~/i18n'
import { useCurrentOrganization } from '~/utils/organization'
import { getCurrentUser } from '~/utils/auth'
import { roles } from '~/utils/auth'

import styles from './logo.module.scss'

const DEFAULT_LOGO = '/logo/hiago.svg'

const Logo = () => {
  const [organization, loading] = useCurrentOrganization()
  const hasOrganization = !!organization
  const user = getCurrentUser()
  const userRole = user?.userCredentials?.nodes[0]?.role || roles.guest
  const src = organization?.logoUrl || DEFAULT_LOGO
  const alt = `${organization?.name} logo`
  const visibility = loading ? 'hidden' : 'visible'
  const linkPropsOptions = {
    [roles.user]: {
      href: "/organizations/[slug]",
      as: `/organizations/${organization?.slug}`
    },
    [roles.guest]: {
      href: "/organizations/[slug]",
      as: `/organizations/${organization?.slug}`
    },
    [roles.administrator]: {
      href: '/admin/emails'
    },
    [roles.superadmin]: {
      href: '/admin/organizations'
    }
  }

  const defaultLinkProps = {
    href: "/"
  }

  const linkProps = hasOrganization ? linkPropsOptions[userRole] : defaultLinkProps

  return (
    <Link {...linkProps} passHref prefetch={false}>
      <a>
        <img className={styles.logo} style={{ visibility }} {...{ src, alt }} />
      </a>
    </Link>
  )
}

export default Logo
